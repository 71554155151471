/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
@import '~material-icons/iconfont/material-icons.css';
@import '~leaflet/dist/leaflet.css';
.leaflet-popup-content{
    margin: 0;
}
.leaflet-container a.leaflet-popup-close-button {
    position: absolute;
    top: 12px;
    right: 18px;
    border: none;
    text-align: center;
    width: 18px;
    height: 20px;
    font: 16px/24px Tahoma, Verdana, sans-serif;
    color: white;
    font-size: x-large;
    text-decoration: none;
    background: transparent;
    margin-top: 2px;
}
.marker-label{
    padding-left: 10px;
    padding-bottom: 0px;
    padding-top: 0px;
    margin: 0px;
}
.marker-info{
    padding-left: 10px;
    padding-bottom: 0px;
    padding-top: 0px;
    margin: 0px;
}
.mat-h2, .mat-title, .mat-typography h2 {
    font: 500 20px/32px Roboto, "Helvetica Neue", sans-serif;
    letter-spacing: normal;
    margin: 0 0 0px;
}
.mat-h3, .mat-subheading-2, .mat-typography h3 {
    font: 400 16px/28px Roboto, "Helvetica Neue", sans-serif;
    letter-spacing: normal;
    margin: 0 0 0px;
}
.comments{
    padding: 5px 10px 0 10px;
    font-family: Inter;
    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0.02em;
    text-align: left;

}
.custom-btn {
    width: 280px; 
    height: 30px; 
    border-radius: 15px; 
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0.02em;
    text-align: center;
    
}
h2{
    font-family: Roboto;
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.05em;
    text-align: left;
    color: #121212;
}

.coordinates{
    font-family: Roboto;
    font-size: 10px;
    font-weight: 900;
    line-height: 9px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #1D53FF;
    width: 15px;
    height: 9px;
}
.lat {
    font-family: Roboto;
    font-size: 10px;
    font-weight: 500;
    line-height: 9px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #737373;
    width: 95px;
    height: 9px;
}

.coordinates-container {
    display: flex;
    justify-content: space-between;
    font-family: Arial, sans-serif;
    font-size: 16px;
    margin: 0 10px;
}
  
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.info-box {
    border-radius: 5px;
    margin: 10px;
    border: 2px solid #DADADA;
    word-wrap: break-word;
    overflow: hidden;
  
}
  
.label {
    font-weight: bold;
    display: inline-block;
    width: 160px;
  } 
.value {

    text-align: right;
}
.address, .economy , .traffic, .speed {
    display: flex;
    justify-content: space-between;
}

.address .values {
    display: flex;
    flex-direction: column;
  }
.label {
    text-align: left;
}

.values {
    text-align: right;
}
.traffic .value {
    position: relative;
    text-align: right;
}
.traffic .value::before {
    content: "";
    display: inline-block;
    width: 7px;
    height: 7px;
    background: #D2A609;
    border-radius: 50%;
    margin-right: 5px; 
    vertical-align: middle;
}
.traffic .traffic-value::before {
    content: "";
    display: inline-block;
    width: 7px;
    height: 7px;
    background: #D2A609;
    border-radius: 50%;
    margin-right: 5px;
    vertical-align: middle;
    margin-top: -2px;
}
.speed .speed-value::before {
    content: "";
    display: inline-block;
    width: 7px;
    height: 7px;
    background: #d20913;
    border-radius: 50%;
    margin-right: 5px; 
    vertical-align: middle;
}
.speed .value {
    position: relative;
    text-align: right;
}
.mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 0 0 0 0;
}
.leaflet-popup-content-wrapper{
    width: 450px;
    border-radius: 20px;
}


$color_black: #000000;
$color_white: #ffffff;
$color_grey: rgba(0, 0, 0, 0.35);
$color_grey1: rgba(255, 255, 255, 0.83);
$color_grey2: #656363;
$color_grey3: #D9D9D9;
$color_grey4: #F5F5F5;
$color_grey5: #636262;
$color_grey6: #FCFCFC;
$color_grey7: rgba(150, 150, 150, 0.25);
$color_grey8:  rgba(0, 0, 0, 0.1);
$color_grey9: rgba(99, 98, 98, 0.7);
$color_grey10: rgb(0 0 0 / 25%);
$color_blue: #003368;
$color_blue1: #3F8CB826;
$color_blue2: #3F8CB8;
$color_blue3: #1887D7;
$color_blue4: #0074A5;
$color_blue5: rgba(63, 140, 184, 0.14);
$color_blue6: rgba(63, 140, 184, 0.35);
$color_blue7: #063081;
$color_blue8: #F7F9FC;
$color_yellow: #F9C50F;
$color_green: #008E45;
.trend-dialog .mat-dialog-container{
  overflow-y: hidden !important;
}
//========================BillboardPopup==================================//
.billboard-container{
    width: 450px;
    height: 310px;
    border-radius: 20px; 
    cursor: grab; 
    position: absolute;
    background-color: white;
}
.header-container{
    display: flex; 
    align-items: center; 
    height: 60px; background: #1F6984; 
    color: white; 
    border-top-right-radius: 20px; 
    border-top-left-radius: 20px;
}
.content-container{
    display: flex; align-items: center; margin: 25px 5px 5px 0px;
}
.icon-container{
    display: flex; align-items: center; margin: 5px 0px 10px 10px;
}
.icon-container-point{
    display: flex;justify-content: center;
}
.delete-marker-point{
    margin-left: -12px;
}
.icon{
    width: 30px; height: 30px;
}
#address{
    color: #737373; 
    font-weight: 500; 
    font-size: 10px;
}
#street-view{
    text-align: center; 
    margin: 0 10px 0 0px ; 
    font-size: 8px;
    color: #1F6984;
    cursor: pointer;
}
#delete-button{
    text-align: center; 
    margin: 0 10px 0 40px ; 
    font-size: 8px;
    color: #1F6984;
    cursor: pointer;
}
#delete-button-point{
    text-align: center; 
    font-size: 8px;
    color: #1F6984;
    cursor: pointer;
}
#other-buttons{
    text-align: center; 
    margin:  0 10px 0 50px ; 
    font-size: 8px;
    color: #1F6984;
    cursor: pointer;
}
#user-street-view{
    text-align: center; 
    margin: 0 10px 0 20px ; 
    font-size: 8px;
    color: #1F6984;
    cursor: pointer;
}
#user-other-buttons{
    text-align: center; 
    margin:  0 10px 0 50px ; 
    font-size: 8px;
    color: #1F6984;
    cursor: pointer;
}
#google-satellite-label-add{
    margin-left: -10px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
#traffic-p{
    margin: 5px 0px 5px 0px; font-size: 10px; font-weight: 900; color: #545454; display: flex; justify-content: space-between;
}
#traffic-v-p{
    margin-left: 10px; color: #737373; font-weight: 500; font-size: 10px;
}
#hl{
    border-bottom: 1px solid #ccc; width: 450px;margin-top: 10px;margin-bottom: 10px;
}
#vh{
    margin-left: 10px; color: #737373; font-weight: 500; font-size: 10px;
}
#lat-paragraph{
    margin: 0; font-size: 10px; font-weight: 900; color: #1D53FF; display: flex; justify-content: space-between;
}   
#lon-paragraph{
    margin: 5px 0px 5px 0px; font-size: 10px; font-weight: 900; color: #1D53FF; display: flex; justify-content: space-between;
}
.details-container{
    display: flex; flex-direction: column; justify-content: flex-start; width: 230px;
}
#content-location{
    margin: 5px 0px 5px 0px; font-size: 10px; font-weight: 900; color: #545454; display: flex; justify-content: space-between;
}
#address-holder{
    text-align: right; display: flex; flex-direction: column; align-items: flex-end;
}
#icon-home{
    margin-left: 10px; margin-right: 10px;margin-top:-5px;font-size: 24px;
}
#vl{
    border-left: 1px solid white; height: 60px; margin-left: 10px;
}
#img-holder{
    margin-left: 30px; margin-right: 10px;
}
.leaflet-popup-content-wrapper {
    padding: 0px;
}
//========================EndofBillboardPopup==================================//
//===================Registration Page==========================================
.mat-horizontal-content-container {
    overflow: hidden;
    padding: 1px 1px 1px 1px;
}
.progress, .progress-stacked {
   --bs-progress-height: 0.2rem;
   width: 240px;
   align-items: center;
   --bs-progress-bar-bg:#00B950;
}
.mat-progress-bar-fill::after {
    background-color: #00B950;
}
//========================Notification Toast=============================

.mat-select {
    margin-top: 10px;
}

//========================input autofill background color =================
input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px white inset !important;
}
  
input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 1000px white inset !important;
    outline: none;
}
.mat-form-field-appearance-legacy .mat-form-field-infix {
    margin-left: 10px;
    
}

//==================google satellite============================
#google-circle-container{
    background-color: #1F6984;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
}
.icon-google{
    width: 15px;
    height: 15px;
}
#google-satellite-label{
    margin-left: -15px;
    display: flex;
    justify-content: center;
    align-items: center;
}



@media (max-width: 600px) {
.mat-horizontal-stepper-header-container {
    display: none!important;
  }
}